<template>
  <div>
    <div align="center" class="my-5" v-if="dati_vuoti == ''">
      <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      variant="primary"
      />
    </div>

    <section id="dashboard-ecommerce" class="mb-5" v-if="dati_vuoti != ''">

      <h3 class="pb-1">N.Ordine: {{ data.ordine.numero_ordine }}</h3>
          
      <righe-articoli-tabella :table-data="data.ordine_righe" />

      <b-row>
        <b-col
          xl="7"
          md="5"
        >
          <b-card bg-variant="Default" v-if="data.ordine.cig != ''">
            <i>Numero CIG:</i><br>
            {{ data.ordine.cig }}
          </b-card>
          <b-button
            variant="outline-primary"
            @click="$router.push({ name: 'azienda-lead-dettaglio', params: { id_lead: data.ordine.id_lead }})"
            >
                <feather-icon
                    icon="FileTextIcon"
                    size="16"
                    class="mr-1"
                />Richiesta per questo Ordine
            </b-button>
        </b-col>
        <b-col
          xl="5"
          md="7"
        >
          <b-card bg-variant="Default">
            <b-row class="border-bottom" style="padding-bottom: 0.5rem; margin-bottom: 0.5rem;">
              <b-col>Subtotale ordine</b-col>
              <b-col class="text-right">{{ data.ordine.subtotale }} €</b-col>
            </b-row>
            <b-row class="border-bottom" style="padding-bottom: 0.5rem; margin-bottom: 0.5rem;">
              <b-col>Iva ordine</b-col>
              <b-col class="text-right">{{ data.ordine.iva }} €</b-col>
            </b-row>
            <b-row class="border-bottom" style="padding-bottom: 0.5rem; margin-bottom: 0.5rem;">
              <b-col>Sconto applicato</b-col>
              <b-col class="text-right">{{ data.ordine.sconto }} €</b-col>
            </b-row>
            <b-row class="font-weight-bolder">
              <b-col>Totale ordine</b-col>
              <b-col class="text-right">{{ data.ordine.totalone }} €</b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <b-card-body>
              <h3 class="mb-2">Fatturazione</h3>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-top">
                <div class="meetup-day">
                  <span class="text-muted">codice cliente:</span><br />
                  <h5 class="mb-0">
                    {{ data.azienda.codice }}
                  </h5>
                </div>
                <div class="my-auto">
                  <h3 class="mb-25">
                    {{ data.azienda.rag_soc }}
                  </h3>
                  <b-card-text class="mb-25">
                    p.iva: {{ data.azienda.piva }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    sdi: {{ data.azienda.sdi }}
                  </b-card-text>
                </div>
              </div>
              
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.indirizzo }}<br />
                    {{ data.azienda.cap }} - {{ data.azienda.localita }} ({{ data.azienda.provincia }}) - {{ data.azienda.paese }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.telefono }}</h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.azienda.email }}</h6>
                </b-media-body>
              </b-media>

            </b-card-body>

          </b-card>
        </b-col>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <b-card-body>
              <h3 class="mb-2">Spedizione presso</h3>
              <!-- metting header -->
              <h3 class="mb-1">
                {{ data.spedizione.rag_soc }}
              </h3>
              
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.spedizione.indirizzo }}<br />
                    {{ data.spedizione.cap }} - {{ data.spedizione.localita }} ({{ data.spedizione.provincia }}) - {{ data.spedizione.paese }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media no-body v-if="data.spedizione.telefono != '' && data.spedizione.telefono !== null">
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.spedizione.telefono }}</h6>
                </b-media-body>
              </b-media>
              <b-media no-body v-if="data.spedizione.email != '' && data.spedizione.email !== null">
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.spedizione.email }}</h6>
                </b-media-body>
              </b-media>

              <div class="pt-1" v-if="data.spedizione.codice_spe != ''">
                <span class="text-muted">codice indirizzo spedizione:</span><br />
                <h5 class="mb-0">
                  {{ data.spedizione.codice_spe }}
                </h5>
              </div>
            </b-card-body>

          </b-card>

        </b-col>
      </b-row>

      <div class="demo-inline-spacing pb-3 mb-4">
        <h5>Vuoi ordinare nuovamente questi articoli ?</h5>
        <b-button
        variant="outline-primary"
        @click="riOrdinareArticoli()"
        >
          <feather-icon
              icon="RefreshCwIcon"
              size="14"
              class="mr-1"
          />Duplica Ordine
        </b-button>
      </div>
    </section>

  </div>
</template>

<script>
import router from '@/router'
import { BRow, BCol, BButton, BCard, BCardText, BCardTitle, BAvatar, BCardBody, BMedia, BMediaAside, BMediaBody, BSpinner } from 'bootstrap-vue'
import RigheArticoliTabella from './ordineinfo/RigheArticoliTabella.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard, 
    BCardText, 
    BCardTitle,
    BAvatar,
    BCardBody, 
    BMedia, 
    BMediaAside, 
    BMediaBody, 
    BSpinner,
    RigheArticoliTabella,
  },
  data() {
    return {
      dati_vuoti: '',
      data: { },
      userData: {},
      messaggio: '',
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    console.log('richiedi id => '+this.userData.id_azienda)
    console.log('id (speriamo bene) -> '+router.currentRoute.params.id+"\nnumero_ordine -> "+router.currentRoute.params.numero_ordine);
    
    this.$http.get('v2/azienda/infoordinetotale/'+this.userData.id_azienda+'/'+router.currentRoute.params.id+'/'+router.currentRoute.params.numero_ordine)
      .then(response => {
        this.data = response.data

        if(response.data.ordine === false){
          //ordine_trovato
          this.dati_vuoti = '';

          //azzera dati ordine visualizzati
          this.data = { }

          //accesso ad un ordine NON DELLA TUA AZIENDA !!!
          this.$router.replace('../../ordini')
          .then(() => {
            this.$swal({
                icon: 'error',
                title: 'Accesso Negato',
                text: 'hai provato ad accedere ad un ordine non effettuato dalla tua azienda',
                confirmButtonText: 'chiudi',
                customClass: {
                confirmButton: 'btn btn-success',
                },
            })
          })
        } else {          
          //ordine_trovato
          this.dati_vuoti = 'mo';

          //visualizza dati dell'ordine ricevuti
          this.data = response.data
        }

        console.log(response.data);

      }).catch(e => {
        console.log(e);
      });
  },
  methods: {
    riOrdinareArticoli(){
      console.log("cliccato riordinare .....\n"+this.userData.id_azienda+'/'+router.currentRoute.params.id+'/'+router.currentRoute.params.numero_ordine)
      
      this.$http.get('v2/azienda/riordinaordine/'+this.userData.id_azienda+'/'+router.currentRoute.params.id+'/'+router.currentRoute.params.numero_ordine)
      .then(response => {
        this.data = response.data

        if(response.data.status===200){
          //risposta positiva con tutti gli articoli aggiunti con le quantità già ordinate
          this.messaggio = 'Tutti gli articoli sono stati aggiunti al carrello';
        } else {
          //risposta positiva con alcuni articoli aggunti ma con quantità diverse in base all'attual giacenza
          this.messaggio = 'Tutti gli articoli disponibili in magazzino sono stati aggiunti al carrello, alcuni articoli sono disponibili in quantità inferiore al precedente ordine';
        }

        //vai alla visualizzazione dei propri ultimi ordini 
        this.$router.replace('/catalogo/carrello')
        .then(() => {
          this.$swal({
              icon: 'success',
              title: 'Articoli inseriti nel carrello',
              text: this.messaggio,
              confirmButtonText: 'chiudi',
              customClass: {
              confirmButton: 'btn btn-success',
              },
          })

          // Update count in cart items state => 0
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', response.data.articoli_carrello)
        })

      }).catch(e => {
        console.log(e);
      });
    },
    showToast(variant, icona, titolo, testo, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titolo,
          icon: icona,
          text: testo,
          variant,
        },
      },
      {
        position,
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
